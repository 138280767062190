
import {defineComponent} from 'vue';
import IconLink from '@/components/IconLink/IconLink.vue';
import {logoLinkedin} from 'ionicons/icons';
import {Config} from '@/lib/config';

export default defineComponent({
  name: 'LinkedinIconLink',

  setup() {
    return {
      logoLinkedin,
      href: Config.URL.linkedin,
    };
  },

  components: {
    IconLink,
  },
});
