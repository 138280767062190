
import {defineComponent} from 'vue';
import {IonFooter} from '@ionic/vue';

export default defineComponent({
  name: 'AppFooter',

  setup() {
    return {
      year: new Date().getFullYear(),
    };
  },

  components: {
    IonFooter,
  },
});
