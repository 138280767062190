
import {defineComponent} from 'vue';

export default defineComponent({
  name: "ContentSection",
  props: {
    title: {
      type: String,
      required: false,
    },
  },
});
