
import {IonApp, IonRouterOutlet} from '@ionic/vue';
import {defineComponent} from 'vue';
import AppPreloader from '@/components/AppPreloader.vue';
import {preloadImagesAsync} from '@/lib/AppImages';

export default defineComponent({
  name: 'App',

  data() {
    return {
      isLoading: true,
      showPreloader: true,
    };
  },

  mounted() {
    preloadImagesAsync.finally(() => this.isLoading = false);
  },

  methods: {
    handlePreloaderExit() {
      this.showPreloader = false;
    },
  },

  components: {
    IonApp,
    IonRouterOutlet,
    AppPreloader,
  },
});
