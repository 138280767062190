
import {defineComponent} from 'vue';
import IconLink from '@/components/IconLink/IconLink.vue';
import {Config} from '@/lib/config';
import {logoGithub} from 'ionicons/icons';

export default defineComponent({
  name: 'GithubIconLink',

  inheritAttrs: false,

  props: {
    href: {
      type: String,
      default: Config.URL.github,
    },
  },

  setup() {
    return {
      logoGithub,
    }
  },

  components: {
    IconLink,
  },

});
