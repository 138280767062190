
import {defineComponent} from 'vue';
import IconLink from '@/components/IconLink/IconLink.vue';
import {Config} from '@/lib/config';
import {mail} from 'ionicons/icons';

export default defineComponent({
  name: 'EmailIconLink',

  inheritAttrs: false,

  props: {
    href: {
      type: String,
      default: Config.URL.email,
    },
  },

  setup() {
    return {
      icon: mail,
    }
  },

  components: {
    IconLink,
  },

});
