
import {defineComponent} from 'vue';
import GithubIconLink from '@/components/IconLink/GithubIconLink.vue';
import LinkedinIconLink from '@/components/IconLink/LinkedinIconLink.vue';
import EmailIconLink from '@/components/IconLink/EmailIconLink.vue';

export default defineComponent({
  name: "Contact",

  components: {
    GithubIconLink,
    LinkedinIconLink,
    EmailIconLink,
  },

});
