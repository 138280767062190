
import {defineComponent, PropType} from 'vue';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSkeletonText,
  IonIcon,
} from '@ionic/vue';
import {ProjectResponse} from '@/lib/api/api';
import {alertCircleOutline} from 'ionicons/icons';

export default defineComponent({
  name: 'AppCard',

  props: {
    project: {
      type: Object as PropType<ProjectResponse>,
    },
    loading: {
      type: Boolean,
    },
  },

  setup() {
    return {
      alertCircleOutline,
    }
  },

  components: {
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonSkeletonText,
  }
});
