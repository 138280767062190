
import {defineComponent} from 'vue';
import {IonIcon} from '@ionic/vue';

export default defineComponent({
  name: 'IconLink',

  inheritAttrs: false,

  props: {
    icon: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: "_blank",
    },
    title: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: "white",
    },
    bgColor: {
      type: String,
      default: "transparent",
    },
    activeColor: {
      type: String,
      default: "var(--ion-color-primary)",
    },
    activeBgColor: {
      type: String,
      default: "white",
    },
    noDrop: {
      type: Boolean,
      required: false,
    }
  },

  mounted() {
    const iconLink: HTMLAnchorElement = this.$refs.iconLink as HTMLAnchorElement;
    iconLink.style.setProperty("--color", this.color);
    iconLink.style.setProperty("--background-color", this.bgColor);
    iconLink.style.setProperty("--active-color", this.activeColor);
    iconLink.style.setProperty("--active-background-color", this.activeBgColor);
  },

  computed: {
    linkTitle(): string {
      return this.title ? this.title : this.$attrs.href as string;
    },
  },

  components: {
    IonIcon,
  },
});
