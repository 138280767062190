
import {defineComponent, ref, Ref} from 'vue';
import {SiteDataService} from '@/lib/SiteDataService';
import {SiteDataResponse} from '@/lib/api/api';

export default defineComponent({
  name: 'About',

  setup() {

    const siteData: Ref<string | null> = ref(null);

    SiteDataService.fetchSiteData()
      .then((response: SiteDataResponse) => {
        siteData.value = response.about_me_html;
      });

    return {
      siteData,
    };
  },

});
