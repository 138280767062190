
import {defineComponent} from 'vue';
import {closeOutline} from 'ionicons/icons';
import {IonButton, IonIcon} from '@ionic/vue';

export default defineComponent({
  name: 'CloseButton',

  inheritAttrs: false,

  setup() {
    return {
      closeOutline,
    };
  },

  components: {
    IonIcon,
    IonButton,
  },

});
