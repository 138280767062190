
import {defineComponent, ref, computed} from 'vue';
import RoundButton from './RoundButton.vue';
import {chevronDownSharp, chevronUpSharp} from 'ionicons/icons';

export default defineComponent({
  name: "GenericComponent",

  setup() {
    const isExpanded = ref(false);

    const toggleExpanded = (event: MouseEvent) => {
      event.stopPropagation();
      isExpanded.value = !isExpanded.value;
    };

    const handleClick = (event: MouseEvent) => {
      if (!isExpanded.value) {
        toggleExpanded(event);
      }
    };

    const expander = computed(() => {
      const title = isExpanded.value ? "See Less" : "See More";
      const icon = isExpanded.value ? chevronUpSharp : chevronDownSharp;
      const contentClass = isExpanded.value ? "expanded" : "";

      return {
        title,
        icon,
        contentClass,
      };
    });

    return {
      isExpanded,
      toggleExpanded,
      handleClick,
      chevronDownSharp,
      expander,
    };
  },

  components: {
    RoundButton,
  },
});
