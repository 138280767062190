
import {defineComponent, ref, watch} from 'vue';
import {IonProgressBar} from '@ionic/vue';

export default defineComponent({
  name: 'AppPreloader',

  props: {
    loading: Boolean,
  },

  setup(props, {emit}) {
    const showIndeterminate = ref(true);
    const determinateLoaderVal = ref(0);

    const emitExit = () => {
      emit("exit");
    }

    if (!props.loading) {
      emitExit();
    }

    watch(() => props.loading, () => {
      showIndeterminate.value = false;
      determinateLoaderVal.value = 100;

      setTimeout(() => {
        emitExit();
      }, 300);
    });

    return {
      showIndeterminate,
      determinateLoaderVal,
    }
  },

  components: {
    IonProgressBar,
  },
});
