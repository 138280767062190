
import {defineComponent, PropType, toRefs} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import AppFooter from '@/components/AppFooter.vue';

export default defineComponent({
  name: 'AppPage',

  props: {
    invert: {
      type: Boolean,
      required: false,
    },
    hero: {
      type: Object as PropType<HTMLImageElement>,
      required: true,
    }
  },

  setup(props) {
    const {hero} = toRefs(props);

    return {
      bgImageStyle: { backgroundImage: `url(${hero.value.currentSrc})` },
    };
  },

  components: {
    IonPage,
    IonContent,
    AppFooter,
  },
});
