
import {defineComponent, PropType} from 'vue';
import {IonButton, IonContent, IonHeader, modalController} from '@ionic/vue';
import {closeOutline, enterOutline} from 'ionicons/icons';
import AppImageCarousel from '@/components/AppImageCarousel.vue';
import {ProjectResponse} from '@/lib/api/api';
import GithubIconLink from '@/components/IconLink/GithubIconLink.vue';
import CloseButton from '@/components/CloseButton.vue';

export default defineComponent({
  name: 'ProjectModal',

  props: {
    project: {
      type: Object as PropType<ProjectResponse>,
      required: true,
    },
  },

  setup() {

    const closeModal = async () => {
      await modalController.dismiss();
    };

    return {
      enterOutline,
      closeOutline,
      closeModal,
    }
  },

  data() {
    return {
      viewSourceOverride: false,
    };
  },

  mounted() {
    document.styleSheets[0].insertRule(`.p-header::before {--background-image: url(${this.project.thumbnail.link})}`, 0);
  },

  beforeUnmount() {
    document.styleSheets[0].deleteRule(0);
  },

  computed: {
    projectLink(): string {
      return this.project.project_url;
    },
  },

  components: {
    IonContent,
    IonHeader,
    IonButton,
    AppImageCarousel,
    GithubIconLink,
    CloseButton,
  },
});
