
import {defineComponent, PropType} from 'vue';
import {MediaResponse} from '@/lib/api/api';

export default defineComponent({
  name: 'MediaContent',

  props: {
    media: {
      type: Object as PropType<MediaResponse>,
      required: true,
    }
  },

  data() {
    return {
      styleBlacklist: ["schedule-explorer-0.mp4"],
    };
  },

  mounted() {
    if (this.isImage) {
      const mediaContent = this.$refs.mediaContent as HTMLElement;
      mediaContent.style.backgroundImage = `url(${this.media.link})`;
    }
  },

  computed: {
    isImage(): boolean {
      return this.media.content_type !== "video/mp4";
    },

    isStyleBlacklist(): boolean {
      return this.styleBlacklist.includes(this.media.filename);
    },

    mediaContentClass() {
      return {
        'media-content': true,
        'sbl': this.isStyleBlacklist,
        'image': this.isImage,
        'video': !this.isImage,
      }
    },
  },

});
