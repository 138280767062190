
import {defineComponent} from 'vue';
import {RouterLink} from 'vue-router';
import {IonFabButton, IonIcon} from '@ionic/vue';
import {albumsOutline} from 'ionicons/icons';

export default defineComponent({
  name: "RoundButton",

  props: {
    title: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: albumsOutline,
    },
    handler: {
      type: Function,
    },
    inactive: {
      type: Boolean,
      required: false,
    },
    route: {
      type: String,
      required: false,
    },
    buttonClass: {
      type: String,
      default: "none",
    },
    color: {
      type: String,
      default: "black",
    },
  },

  mounted() {
    const roundButton: HTMLDivElement = this.$refs.roundButton as HTMLDivElement;

    if (roundButton) {
      roundButton.style.setProperty("--color", this.color);
    }
  },

  components: {
    IonFabButton,
    IonIcon,
    RouterLink,
  },
});
